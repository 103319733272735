<template>
    <div>
        <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
                <template v-slot:header>
                    <h6 class="mb-0">Bounce Mail</h6>
                </template>
                <b-row>
                    <b-col class="col-3">
                        <b-form-group label="Email">
                            <b-form-input
                                v-model="input.email">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-3">
                        <b-form-group label="Nguồn">
                            <b-form-select
                                v-model="input.is_ipn"
                                :options="this.options.source">
                            </b-form-select>
                        </b-form-group>
                    </b-col>
<!--                    <b-col class="col-3">-->
<!--                        <b-form-group label="Loại lỗi">-->
<!--                            <b-form-select-->
<!--                                v-model="input.type"-->
<!--                                :options="this.options.types">-->
<!--                            </b-form-select>-->
<!--                        </b-form-group>-->
<!--                    </b-col>-->
                    <b-col class="col-3">
                        <b-form-group label="Trạng thái đồng bộ">
                            <b-form-select
                                v-model="input.is_sync"
                                :options="this.options.status_sync">
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-3">
                        <b-form-group label="Ngày lỗi bắt đầu(*)">
                            <b-form-input
                                :id="`type-date`"
                                :type="`date`"
                                v-model="input.from"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-3">
                        <b-form-group label="Ngày lỗi kết thúc(*)">
                            <b-form-input
                                :id="`type-date`"
                                :type="`date`"
                                v-model="input.to"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-right">
                        <b-button class="mr-2" variant="outline-primary" @click="getList(1)"
                        >Tìm kiếm
                        </b-button>
                        <b-button
                            class="mr-2"
                            variant="outline-primary"
                            @click="linkReset()"
                        >Reset
                        </b-button>
                        <b-button
                            class="mr-2"
                            variant="outline-primary"
                            @click="linkCreate()"
                        >Thêm mới
                        </b-button>
                    </b-col>
                </b-row>

                <hr/>
                <b-table
                    :items="items"
                    :fields="fields"
                    ref="table"
                    striped
                    hover
                    responsive
                    caption-top
                >
                    <template #cell(is_ipn)="data" >
                        <b-badge
                            v-if="data.item.is_ipn == 1"
                            variant="secondary"
                        > Từ IPN
                        </b-badge>
                        <b-badge v-if="data.item.is_ipn == 0" variant="secondary"
                        > Từ import
                        </b-badge>
                    </template>

                    <template #cell(is_sync)="data">
                        <b-badge
                            v-if="data.item.is_sync == 1"
                            variant="secondary"
                        > Đã đồng bộ
                        </b-badge>
                        <b-badge v-if="data.item.is_sync == 0" variant="secondary"
                        > Chưa đồng bộ
                        </b-badge>
                    </template>

                    <template #cell(description)="data" >
                        <div style="overflow: scroll;max-height: 50px;">{{data.item.description}}</div>
                    </template>

                    <template #cell(action)="data">
                        <router-link
                            :to="'/tools/bounce-mail/create?id=' + data.item.email"
                        >
                            <b-button style="margin-right: 10px;" pill variant="info" size="sm">Chi tiết</b-button>
                        </router-link>
                        <b-button
                            @click="destroy(data.item.email)"
                            pill
                            variant="danger"
                            size="sm"
                        >Xóa
                        </b-button>
                    </template>

                    <template #cell(failed_at)="data">
                        <div>
                            {{ formatDateFromTime(data.item.failed_at) }}
                        </div>
                    </template>

                    <template #cell(created_at)="data">
                        <div>
                            {{ formatDateFromTime(data.item.created_at) }}
                        </div>
                    </template>

                </b-table>
                <div class="mt-3">
                    <b-pagination-nav
                        v-model="currentPage"
                        :link-gen="createLinkPage"
                        :number-of-pages="paginate.totalPage"
                        use-router
                        first-number
                        last-number
                        align="right"
                    ></b-pagination-nav>
                </div>
            </b-card>
        </b-card-group>
    </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import Vue from "vue";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
    mixins: [Common],
    data() {
        return {
            input: {
                email: null,
                type_error: null,
                is_ipn: null,
                type: null,
                description: null,
                is_sync: null,
                from: null,
                to: null,
            },
            options: {
                source: [
                    {value: null, text: "Tất cả"},
                    {value: 1, text: "Từ IPN AWS"},
                    {value: 0, text: "Import"},
                ],
                status_sync: [
                    {value: null, text: "Tất cả"},
                    {value: 1, text: "Đã đồng bộ"},
                    {value: 0, text: "Chưa đồng bộ"},
                ],
                types:[
                    {value: null, text: "Tất cả"},
                    {value: 'BOUNCE', text: 'BOUNCE'},
                    {value: "COMPLAINT", text: "COMPLAINT"},
                ]
            },
            query: null,
            items: [],
            currentPage: 1,
            paginate: {
                limit: 25,
                page: 1,
                totalPage: 1,
                total: 0,
                skip: 0,
            },
            fields: [
                {
                    key: "id",
                    label: "ID",
                },
                {
                    key: "email",
                    label: "Email",
                },
                // {
                //     key: "type_error",
                //     label: "Loại lỗi"
                // },
                {
                    key: "is_sync",
                    label: "Đồng bộ"
                },
                {
                    key: "is_ipn",
                    label: "Nguồn"
                },
                {
                    key: "description",
                    label: "Mô tả",
                    thStyle:{width:"20%"}
                },
                {
                    key: "failed_at",
                    label: "Thời gian lỗi",
                },
                {
                    key: "created_at",
                    label: "Thời gian tạo",
                },  {
                    key: "action",
                    label: "Hành động",
                    thStyle:{width:"150px"}
                },],
        };
    },
    created() {
        this.query = this.$route.query;
        this.getList();
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Config bounce mail", route: "bounceMail"},
        ]);
    },
    methods: {
        linkCreate() {
            return this.$router.push({name: "bounceMailCreate"});
        },
        linkReset() {
            this.input= {
                email: null,
                type_error: null,
                is_ipn: null,
                description: null,
                is_sync: null,
                from: null,
                to: null,
            };
            this.getList();
        },
        linkDetail(id) {
            this.$router.push("/tools/bounce-mail/view?id=" + id);
        },
        getList(page = 1) {
            this.items = [];
            let params = {...this.input};
            params.page = page;

            params.limit = 25;
            this.$bus.$emit("show-loading", true);
            CmsRepository.listBounceMail(params)
                .then((response) => {
                    if (response.data.error_code) {
                        this.notifyAlert("warn", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return;
                    }
                    response = response.data;
                    this.items = response.data.data;
                    this.paginate.total = response.data.total;
                    this.paginate.totalPage = response.data.last_page;
                    this.paginate.currentPage = response.data.current_page;
                    this.$bus.$emit("show-loading", false);
                    return;
                })
                .catch(() => {
                    this.$bus.$emit("show-loading", false);
                    alert("Có lỗi xảy ra");
                });
        },
        async destroy(id) {
            if (confirm("Bạn có chắc xóa")) {
                try {
                    this.$bus.$emit("show-loading", true);
                    let response = await CmsRepository.deleteBounceMail(id);
                    this.$bus.$emit("show-loading", false);
                    if (0 === response.data.error_code) {
                        this.$notify({
                            group: "foo",
                            type: "success",
                            title: response.data.message,
                        });
                        return this.getList(this.currentPage);

                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: response.data.message,
                        });
                        this.getList();
                    }
                } catch (e) {
                    this.$bus.$emit("show-loading", false);
                }
            }
        },
    },
    watch: {
        currentPage() {
            this.getList(this.currentPage);
        },
    },

};
</script>
